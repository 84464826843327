/**
 * Component for rendering raw HTML into Vue components
 *
 * @see https://stackoverflow.com/questions/51006553/render-v-html-as-vue-components
 *
 */
Vue.component('html-component', {
    props: ['code'],
    render(h){
        return h(Vue.compile(this.code)) // compile and render 'code' string
    }
});

/**
 * Component for rendering he list of News items with infinite scroll
 *
 * New content will be loaded by XHR and will be passed on as raw HTML
 * The raw HTML blocks are compiled als Vue code with working components (via html-component)
 *
 */
Vue.component("news-component", {
    mixins: [Mixins.queryString, Mixins.viewport],
    props: ['queryParameterPage', 'queryParameterComplete', 'queryParameterCategory', 'hasMoreProp'],
    data: function () {
        return {
            // The hash which the page was initially loaded with
            initialFragment: window.location.hash,
            // Is the component still initializing ?
            isInitializing: true,
            // Is the XHR currently handling a request
            xhrIsLoading: false,
            // What was the last XHR response
            xhrResponse: null,
            // Did the last XHR request return an error ?
            xhrHasError: false,
            // Are we currently keeping track of the visible item ?
            checkVisibleEnabled: false,
            // A small timeout container, to prevent flooding of the browser
            checkVisibleItemTimeout: false,
            // Should we hide the original HTML
            hideOriginalHtml: false,
            // Container for storing the HTML content that was added later on via XHR
            htmlResults: [],
            // Recaptcha
            hasCaptcha: false,
            // Has more pages
            hasMore: this.hasMoreProp,
            // Filter category
            category: 'all',
        }
    },
    created() {
        // First strip of any hash in the initial fragment
        if(this.initialFragment.indexOf('#') == 0) {
            this.initialFragment = this.initialFragment.substr(1);
        }

        //
        let category = parseInt(this.getQueryStringParameter(window.location.href, this.queryParameterCategory));
        if (!isNaN(category) && category > 0) {
            this.category = category;
        }

        // Check if there is a page requested higher then the first one
        let page = parseInt(this.getQueryStringParameter(window.location.href, this.queryParameterPage));
        if (!isNaN(page) && page > 0 && page != 1) {
            // Request the entire content from page 1 to $page
            this.getPage(page, true, true);
        } else {
            // Set state tot beeing initialized
            this.isInitializing = false;

            // Enable the visible item tracker
            this.checkVisibleEnabled = true;
        }
    },
    mounted() {},
    computed: {
        /**
         * Return the current page, based on either the last meta data or else default
         *
         * @returns {*}
         */
        currentPage() {
            if (this.xhrResponse != null && this.xhrResponse.hasOwnProperty('meta')) {
                return this.xhrResponse.meta.current_page;
            }

            return 1;
        }
    },
    methods: {
        /**
         * Start an XHR requst to the server to retrieve results
         *
         * @param page          The page number
         * @param complete      Should the back-end return the complete contents of page 1 to $page
         * @param toFragment    When the request is finished and processed, should the browser scroll to the active hash (if any)
         */
        getPage(page, complete = false, toFragment = false) {

            // Set the XHR loading state to true
            this.xhrIsLoading = true;

            // Create the desired URL
            let url = window.location.href;

            url = this.updateQueryStringParameter(url, this.queryParameterPage, page);
            url = this.updateQueryStringParameter(url, this.queryParameterComplete, (complete ? 1 : 0));
            url = this.updateQueryStringParameter(url, this.queryParameterCategory, this.category !== 'all' ? this.category : '');

            // Start the request
            axios({
                method: 'get',
                url: url,
            }).then((response) => {
                // Store the XHR response
                this.xhrResponse = response.data;

                // Update the current page parameter
                this.updateParameters();

                // Remove old items if it is a complete load
                if(complete) {
                    this.hideOriginalHtml = true;
                    this.htmlResults = [];
                }

                // Append or replace hte HTML content
                if(this.xhrResponse.hasOwnProperty('blocks') && this.xhrResponse.blocks.length > 0) {
                    for(let blockI = 0; blockI < this.xhrResponse.blocks.length; blockI++) {
                        // Push the HTML into the components array
                        this.htmlResults.push(this.xhrResponse.blocks[blockI]);
                    }
                }

                // Set paging
                if(response.data.meta.current_page == response.data.meta.last_page || this.xhrResponse.blocks.length == 0){
                    this.hasMore = false;
                }else{
                    this.hasMore = true;
                }

                // Set the initialization state to false
                this.isInitializing = false;

                // Set the XHR loading state to false
                this.xhrIsLoading = false;

                // Because we've manually placed HTML, it's better to wait until the next update cycle to make sure everything is in the DOM
                this.$nextTick(() => {
                    setTimeout(function() {
                        // Tell Blazy to revalidate the images
                        this.$root.bLazy.revalidate();
                    }.bind(this), 1);
                });
            }).catch((error) => {
                // An error is recieved when there are no more pages left

                // Set the initialization state to false
                this.isInitializing = false;

                // Set the XHR loading state to false
                this.xhrIsLoading = false;

                // Set the XHR error state to true
                this.xhrHasError = true;
            });
        },
        /**
         * Update the browsers address bar/history with the current page number
         */
        updateParameters() {
            let url = window.location.href;

            url = this.updateQueryStringParameter(url, this.queryParameterPage, this.currentPage);
            url = this.updateQueryStringParameter(url, this.queryParameterCategory, this.category !== 'all' ? this.category : '');

            window.history.replaceState({}, document.title, url);
        },
        /**
         * TODO:comments
         */
        setCategory(category) {
            if(this.xhrIsLoading || this.category == category) return;

            this.category = category;
            this.getPage(1, true);
        },
        /**
         * TODO:comments
         */
        nextPage() {
            if(this.xhrIsLoading) return;

            this.getPage(this.currentPage+1);
        },
    },
});
